/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

// Meta Component
import Meta from 'components/Meta'

// RadarCategory Route
import RadarCategory from 'routes/templates/radar-category'

// Gatsby SEO Head
export function Head({ data: { category, page } }) {
  return (
    <Meta tags={page.seoMetaTags.tags} hideTitle hideMeta>
      <title>
        Mobile {`${category.title} | Mobile Technology Radar | Pocketworks`}
      </title>
      <meta
        name="title"
        content={`Mobile${category.title} | Mobile Technology Radar | Pocketworks`}
      />
      <meta name="description" content={category.description} />
    </Meta>
  )
}

export default RadarCategory

export const query = graphql`
  query RadarCategoryQuery($slug: String!) {
    page: datoCmsPage(slug: { eq: "mobile-technology-radar" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
    }
    blips: allDatoCmsRadarBlip(
      filter: { category: { slug: { eq: $slug } } }
      limit: 30
      sort: { fields: [meta___updatedAt], order: DESC }
    ) {
      edges {
        node {
          id
          title
          meta {
            updatedAt
          }
          bodyNode {
            childMarkdownRemark {
              excerpt
            }
          }
          slug
          fontAwesomeId
          radarstatus {
            title
          }
          category {
            title
            slug
          }
        }
      }
    }
    categories: allDatoCmsRadarCategory(
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          title
          slug
          position
        }
      }
    }
    category: datoCmsRadarCategory(slug: { eq: $slug }) {
      slug
      title
      description
    }
  }
`
