import React from 'react'

function Suggestion() {
  return (
    <div className="has-text-center">
      <h5 className="has-text-grey">Something missing?</h5>
      Suggest a new strategy, practice, tool or technology to our team on{' '}
      <a href="https://twitter.com/thepocketworks">Twitter</a> or by{' '}
      <a href="mailto:hello@pocketworks.co.uk">email</a>.
    </div>
  )
}

export default Suggestion
