import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

function RadarNav({ categories, category }) {
  return (
    <>
      <div className="tabs is-toggle is-medium is-toggle-rounded  is-centered">
        <ul>
          <li>
            <Link
              to="/mobile-technology-radar"
              activeClassName="is-active"
              partiallyActive
            >
              Spotlight
            </Link>
          </li>
          {categories.edges.map(({ node: cat }, index) => (
            <li
              key={index}
              className={cat.slug === category.slug ? 'is-active' : ''}
              partiallyActive
            >
              <Link to={`/mobile-technology-radar/${cat.slug}`}>
                {cat.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <h3>{category.description}</h3>
      <br />
    </>
  )
}
RadarNav.propTypes = {
  categories: PropTypes.object,
  category: PropTypes.object,
}
export default RadarNav
