import PropTypes from 'prop-types'
import React from 'react'

import { Section, Container, Columns } from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'
import TechCard from 'routes/mobile-technology-radar/components/tech-card'
import RadarCategoryHero from 'routes/mobile-technology-radar/components/radar-category-hero'

// Page Components
import RadarNav from './components/radar-nav'
import Suggestion from './components/suggestion'

function RadarCategory({ data: { blips, categories, category } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <Section>
        <Container>
          <Columns className="is-centered">
            <Columns.Column className="is-9 has-text-centered content">
              <RadarCategoryHero />
              <RadarNav categories={categories} category={category} />
              <TechCard blips={blips} />
              <hr />
              <Suggestion />
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </Layout>
  )
}
RadarCategory.propTypes = {
  data: PropTypes.object,
}
export default RadarCategory
